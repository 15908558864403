import { get, includes } from 'lodash-es';

const aggregateStudies = {
    // Media benchmark for a single touchpoint or multiple touchpoints
    // Ex: Media, View study page (Evals) index pills / category pills, Export plan as pptx index pills
    touchpointFilter: (study, touchpointId, rCategoryId) => {
        return study.numberOfTouchpoints === 1
            && study.status === 'aggregated'
            && !study.neverAggregate
            && (touchpointId ? get(study, 'touchpoints[0]._id') === String(touchpointId) : true)
            && (rCategoryId ? String(rCategoryId) === String(study.rCategory._id) : true);
    },
    // Media benchmark for retailer specific touchpoints
    // Ex: Select touchpoint page (Campaigns), "benchmark brand % uplift"
    touchpointsFilter: (study, retailerId) => {
        return study.numberOfTouchpoints === 1
            && study.status === 'aggregated'
            && !study.neverAggregate
            && get(study, 'retailer._id') === String(retailerId);
    },
    // Trends page
    trendsFilter: (study, shopperJourneyGroup, retailerId, xCategoryId, mediaChannelId) => {
        return study.numberOfTouchpoints === 1
            && study.status === 'aggregated'
            && !study.neverAggregate
            && (!shopperJourneyGroup || get(study, 'touchpoints[0].shopperJourneyGroup') === shopperJourneyGroup)
            && (!retailerId || get(study, 'retailer._id') === retailerId)
            && (!xCategoryId || get(study, 'parentCategory._id') === xCategoryId || get(study, 'category._id') === xCategoryId)
            && (!mediaChannelId || get(study, 'touchpoints[0].mediaChannel._id') === mediaChannelId);
    }
};

export default aggregateStudies;
