type RetailerProductFeedLabels = Record<string, {
    name: string;
    companyName: string;
    productId: string;
    productId2?: string;
    productId3?: string;
}>;

const retailerProductFeedLabels: RetailerProductFeedLabels = {
    // Use the retailer slug as the key
    'asda': {
        name: 'Product',
        companyName: 'Supplier_Name',
        productId: 'EAN',
        productId2: 'CIN',
        productId3: 'WIN'
    },
    'co-op': {
        name: 'Product Name',
        companyName: 'SUPPLIER',
        productId: 'Retail Line',
        productId2: 'EAN',
    },
    'boots': {
        name: 'Name',
        companyName: 'Brand',
        productId: 'Item Code',
    },
    "morrisons": {
        name: 'Product name',
        companyName: 'Supplier name',
        productId: 'EAN',
    },
    "tvg": {
        name: 'Line No Desc',
        companyName: 'Supplier Code Desc',
        productId: 'Line No',
    },
}

export default retailerProductFeedLabels;
