import { Form, Formik } from 'formik';
import * as yup from 'yup';

import { FlashError, FTextareaField, Http, Icon, Popover, PopoverContent, PopoverTrigger, Submit, toast, useAppState } from '../';
import css from './Feedback.module.scss';

const validationSchema = yup.object().shape({
    comment: yup.string().required('Please provide your feedback.'),
});

const initialValues = {
    comment: '',
};

const FeedbackForm = () => {
    return (
        <Form>
            <FlashError />
            <FTextareaField label="Feedback comment" name="comment" required rows="6" />
            <div className="btn-container-right">
                <Submit>Submit</Submit>
            </div>
        </Form>
    );
};

const Feedback = () => {
    const [isOpen, setIsOpen] = useAppState('feedback.isOpen');

    const handleSubmit = (values, formikBag) => {
        formikBag.setStatus(undefined);
        Http.post('/feedback', { ...values, _referrer: window.location.href })
            .then(() => {
                toast.success('Thank you for your feedback!');
                formikBag.setFieldValue('comment', '');
                setIsOpen(false);
            })
            .catch(error => {
                formikBag.setStatus({ errorMsg: error.message || 'An error occured' });
            })
            .finally(() => {
                formikBag.setSubmitting(false);
            });
    };

    const trigger = (<Icon className="like-link no-color" name="thumbs_up_down"  />)

    return (
        <Popover
            open={isOpen}
            onOpenChange={() => setIsOpen(!isOpen)}
        >
            <PopoverTrigger>
                {trigger}
            </PopoverTrigger>
            <PopoverContent
                align="end"
                className={css.content}
            >
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validationSchema={validationSchema}
                    component={FeedbackForm}
                />
            </PopoverContent>
        </Popover>
    );
}

const useFeedback = () => {
    const [isOpen, setIsOpen] = useAppState('feedback.isOpen');
    const show = () => setIsOpen(true);
    const hide = () => setIsOpen(false);

    return { show, hide, isOpen };
};

export default Feedback;
export { useFeedback };
