import { compact, forEach, map, max, min, get } from 'lodash-es';
import formatters from '../formatters.js';

import { addBenchmarkColumns, getTotalActualCostOfActivitiesAndItems, getTotalCostOfActivitiesAndItems, getTotalCostOfActivities } from './activityFunctions.js';

// Returns values needed to calculate total CPM
function getValuesForCpm(activitiesPlusDerived, includeZeroCostActivities) {
    const valuesForCpm = {
        impressions: null,
        missingValuesForImpressions: false,
        missingValuesForCpm: false,
        costForCpm: null,
        impressionsForCpm: null,
    };

    forEach(activitiesPlusDerived, activity => {
        const { cost, impressions } = activity;

        if (Number.isFinite(impressions)) {
            valuesForCpm.impressions += impressions;
        } else {
            valuesForCpm.missingValuesForImpressions = true;
        }

        const activityHasCost = includeZeroCostActivities ? Number.isFinite(cost) : !!cost;

        if (!activityHasCost || !Number.isFinite(impressions)) {
            valuesForCpm.missingValuesForCpm = true;
        }
        // Only calculate total CPM if an activity has a cost AND impressions
        if (activityHasCost && Number.isFinite(impressions)) {
            valuesForCpm.costForCpm += cost;
            valuesForCpm.impressionsForCpm += impressions;
        }
    });

    return valuesForCpm;
}

function calculateTotalCpm(valuesForCpm) {
    return Number.isFinite(valuesForCpm.costForCpm) && Number.isFinite(valuesForCpm.impressionsForCpm) && valuesForCpm.impressionsForCpm !== 0
        ? valuesForCpm.costForCpm / (valuesForCpm.impressionsForCpm / 1000)
        : null;
}

// Returns values needed to calculate total ROI
function getValuesForRoi(activitiesPlusDerived, includeZeroCostActivities) {
    const valuesForRoi = {
        estimatedPoundUplift: null,
        missingValuesForEstimatedRoi: false,
        costForEstimatedRoi: null,
        poundUpliftForEstimatedRoi: null,
    };

    forEach(activitiesPlusDerived, activity => {
        const { cost, estPoundUpliftBrand } = activity;

        // Do not include estPoundUpliftBrand in plan total if excludeEstimates is checked
        if (estPoundUpliftBrand && !activity.excludeEstimates) {
            valuesForRoi.estimatedPoundUplift += estPoundUpliftBrand;
        }

        const activityHasCost = includeZeroCostActivities ? Number.isFinite(cost) : !!cost;

        if (!activityHasCost || !Number.isFinite(estPoundUpliftBrand)) {
            valuesForRoi.missingValuesForEstimatedRoi = true;
        }
        // Only calculate est. roi if an activity has a cost AND estPoundUpliftBrand
        // Do not include estimatedRoi in plan total if excludeEstimates is checked
        if (activityHasCost && Number.isFinite(estPoundUpliftBrand) && !activity.excludeEstimates) {
            valuesForRoi.costForEstimatedRoi += cost;
            valuesForRoi.poundUpliftForEstimatedRoi += estPoundUpliftBrand;
        }
    });

    return valuesForRoi;
}

function calculateTotalRoi(valuesForRoi) {
    return Number.isFinite(valuesForRoi.poundUpliftForEstimatedRoi) && Number.isFinite(valuesForRoi.costForEstimatedRoi)
        ? valuesForRoi.poundUpliftForEstimatedRoi / valuesForRoi.costForEstimatedRoi
        : null;
}

function calculateTotalDiscounts(plan) {
    return plan.activities?.reduce((grandTotal, activity) => {
        const totalActivityDiscountPercentage = activity.discounts?.reduce((activityTotal, activityDiscount) => activityTotal + Number(activityDiscount.discountPercentage), 0);
        const totalActivityDiscountAmount = Number(activity.cost) * (totalActivityDiscountPercentage / 100);

        return grandTotal + totalActivityDiscountAmount;
    }, 0);
}

// Calculates the sum values for a list of activities in a plan (CPM, estimated ROI etc).
function getPlanTotals(plan) {
    if (!plan) {
        return null;
    }

    // activitiesPlusDerived are the same as plan.activities, but with extra derived fields for ROI, CPM etc
    let activitiesPlusDerived = map(plan.activities, activity => addBenchmarkColumns(plan, activity, get(activity, 'touchpoint.impressionsGroup')));
    // Do not include cancelled activities in plan totals
    activitiesPlusDerived = activitiesPlusDerived.filter(activity => !activity.cancelled);

    let valuesForCpm = getValuesForCpm(activitiesPlusDerived, true);
    let valuesForRoi = getValuesForRoi(activitiesPlusDerived, true);
    let totalCpm = calculateTotalCpm(valuesForCpm);
    let totalRoi = calculateTotalRoi(valuesForRoi);

    // PFX-3796 Where a 0 or null activity cost yields Infinity, we should exclude that data point
    if (Number.isFinite(totalCpm)) {
        valuesForCpm = getValuesForCpm(activitiesPlusDerived, false);
        totalCpm = calculateTotalCpm(valuesForCpm);
    }

    if (Number.isFinite(totalRoi)) {
        valuesForRoi = getValuesForRoi(activitiesPlusDerived, false);
        totalRoi = calculateTotalRoi(valuesForRoi);
    }

    const tableTotals = {
        filteredActivities: activitiesPlusDerived,
        cost: getTotalCostOfActivitiesAndItems(plan),
        discounts: calculateTotalDiscounts(plan),
        actualCost: getTotalActualCostOfActivitiesAndItems(plan),
        mediaCost: getTotalCostOfActivities(plan.activities),
        cpm: totalCpm,
        estimatedRoi: totalRoi,
        ...valuesForCpm,
        ...valuesForRoi,
    };

    return tableTotals;
}

function isPlanCancelled(plan) {
    // A plan is cancelled if all the activities are cancelled
    const cancelledActivities = plan.activities.filter(activity => activity.cancelled);
    // Or if the status is draft and it's historic
    return (plan.status === 'draft' && plan.historic)
        || (cancelledActivities.length && cancelledActivities.length === plan.activities?.length)
        || plan.cancelled;
}

// Returns true when there are valid activities to show on planner charts
function hasPlansWithValidActivities(plans) {
    let hasActivities = false;

    forEach(plans, plan => {
        const validActivities = plan.activities?.filter(activity => !activity.cancelled);

        if (validActivities.length) {
            hasActivities = true;
        }
    });

   return hasActivities;
}

function getPlanLiveDate(plan) {
    const earliestStartDate = min(compact(map(plan.activities, activity => {
        // Don't include cancelled activities in dates
        if (!activity.cancelled) {
            return activity.startDate;
        }
    })));

    return formatters.asDate(earliestStartDate);
}

function getPlanEndDate(plan) {
    const latestEndDate = max(compact(map(plan.activities, activity => {
        // Don't include cancelled activities in dates
        if (!activity.cancelled) {
            return activity.endDate;
        }
    })));

    return formatters.asDate(latestEndDate);
}

export {
    getPlanTotals,
    hasPlansWithValidActivities,
    isPlanCancelled,
    getPlanLiveDate,
    getPlanEndDate,
};
