import { useState } from 'react';

import { Button, Icon, Pagination, TextField, useAuth } from 'Components';

import css from './DigitalMediaSearch.module.scss';

interface DigitalMediaSearchCampaign {
    id: string;
    name: string;
}

interface DigitalMediaSearchProps {
    linkedCampaignId?: string;
    linkedCampaignLabel: string;
    onDownloadDataClick?: () => void;
    onLinkClick: (campaignId: string) => void;
    onSearchClick: (searchString: string) => void;
    searchCampaigns?: DigitalMediaSearchCampaign[];
    searchFieldLabel: string;
}

// This component encapsulates the search bar and listing of results. Each digital media supplier has its own wrapper around
// this that  handles the supplier-specific things. It should be possible to reuse these components in the old planner as well.
const DigitalMediaSearch = (props: DigitalMediaSearchProps) => {
    const { linkedCampaignId, linkedCampaignLabel, onDownloadDataClick, onLinkClick, onSearchClick, searchCampaigns, searchFieldLabel } = props;

    const { can } = useAuth();

    const [currentPage, setCurrentPage] = useState(1);
    const [searchString, setSearchString] = useState('');

    const PAGE_SIZE = 5;
    const currentPageCampaigns = searchCampaigns?.slice(((currentPage - 1) * PAGE_SIZE), (currentPage * PAGE_SIZE));

    return (<>
        {linkedCampaignId && (
            <section>
                <span>{linkedCampaignLabel}</span>
                <span>{linkedCampaignId}</span>
                {(can('runDevTasks') && onDownloadDataClick) &&  (
                    <span className="like-link ml-2" onClick={onDownloadDataClick}>Download data now</span>
                )}
            </section>
        )}
        <section className="d-flex justify-content-end mt-3">
            <TextField
                // @ts-expect-error TextField component is not typed
                className="flex-fill"
                isClearable
                onChange={setSearchString}
                value={searchString}
                placeholder={searchFieldLabel}
            />
            <Button className="ml-3" color="primary" onClick={() => onSearchClick(searchString)}>Search</Button>
        </section>
        <section className="mt-3">
            {(searchCampaigns && searchCampaigns.length === 0) && (<span className="text-bold">No campaigns found.</span>)}
            {(searchCampaigns && searchCampaigns.length > 0) && (<>
                <Pagination
                    limit={PAGE_SIZE}
                    total={searchCampaigns.length}
                    onChange={setCurrentPage}
                    page={currentPage}
                />
                <div className="mt-2">
                    {currentPageCampaigns && currentPageCampaigns.map((campaign) => (
                        <article className="box-flat d-flex justify-content-between mt-2 p-3" style={{ overflowWrap: 'anywhere' }} key={campaign.id}>
                            <div>
                                <div>
                                    <span className="text-bold">ID: </span>
                                    <span>{campaign.id}</span>
                                </div>
                                <div className="mt-1">
                                    <span className="text-bold">Name: </span>
                                    <span>{campaign.name}</span>
                                </div>
                            </div>
                            <div>
                                <Icon
                                    className={[campaign.id === linkedCampaignId && css.linked, 'like-link'].join(' ')}
                                    name={campaign.id === linkedCampaignId ? 'link_off' : 'link'}
                                    onClick={() => onLinkClick(campaign.id)}
                                />
                            </div>
                        </article>
                    ))}
                </div>
                <Pagination
                    className="mt-2"
                    limit={PAGE_SIZE}
                    total={searchCampaigns.length}
                    onChange={setCurrentPage}
                    page={currentPage}
                />
            </>)}
        </section>
    </>);
};

export type {
    DigitalMediaSearchCampaign,
};

export default DigitalMediaSearch;
