const yesNoUnknownOptions = [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' },
    { value: 'Unknown', label: 'Unknown' },
];

const studies = {
    analysisGroups: [
        { label: 'Featured SKU', value: 'featured' },
        { label: 'Sub-brand', value: 'subBrand' },
        { label: 'Brand', value: 'brand' },
        { label: 'Sub-category', value: 'subCategory' },
        { label: 'Category', value: 'category' },
        { label: 'Company', value: 'company' },
    ],
    documentTypes: [
        { label: 'PO', value: 'po' },
        { label: 'Report', value: 'report' },
        { label: 'Imagery', value: 'imagery' },
        { label: 'Brief', value: 'brief' },
        { label: 'Other', value: 'other' },
    ],
    methodologies: [
        { label: 'Test vs. control', value: 'test-vs-control' },
        { label: 'Pre vs. live', value: 'pre-vs-live' },
        { label: 'Regression', value: 'regression' },
        { label: 'Ecomm sales attribution', value: 'ecomm' },
    ],
    promotionTypes: [
        { label: 'Unknown', value: 'unknown' },
        { label: 'Price reduction', value: 'price-reduction' },
        { label: 'Multibuy', value: 'multibuy' },
        { label: 'BOGOF', value: 'BOGOF' },
        { label: 'Round £', value: 'round £' },
        { label: 'EDLP', value: 'edlp' },
        { label: 'Loyalty card promo', value: 'loyalty-card-promo' },
    ],
    statuses: [
        { label: 'Draft', value: 'draft' },
        { label: 'Completed', value: 'completed' },
        { label: 'Published', value: 'published' },
        { label: 'Aggregated', value: 'aggregated' },
    ],
    // Should match creativeTypeOptions in config/touchpoints.js
    touchpointCreative: [
        { label: 'Unknown', value: 'unknown' },
        { label: 'Templated, no price', value: 'templated-no-price' },
        { label: 'Templated & price', value: 'templated-and-price' },
        { label: 'Branded, no price', value: 'brand-no-price' },
        { label: 'Branded & price', value: 'brand-and-price' },
        { label: 'Templated, event', value: 'templated-event' },
        { label: 'Part Branded', value: 'part-branded' },
    ],
    yesNoUnknownOptions,
    competitionPrizeOptions: [
        { value: 'holiday', label: 'Holiday' },
        { value: 'experience', label: 'Experience' },
        { value: 'money', label: 'Money' },
        { value: 'item', label: 'Item' },
        { value: 'other', label: 'Other' },
        { value: 'unknown', label: 'Unknown' },
    ],
    entryMechanicsOptions: [
        { value: 'text-to-win', label: 'Text to win' },
        { value: 'website', label: 'Website' },
        { value: 'social-media-entry', label: 'Social media entry' },
        { value: 'scan-QR', label: 'Scan QR' },
        { value: 'loyalty-card', label: 'Loyalty card' },
        { value: 'unknown', label: 'Unknown' },
    ],
    npdTypeOptions: [
        { value: 'true-innovation', label: 'True innovation' },
        { value: 'range-extension', label: 'Range extension' },
        { value: 'new-range', label: 'New range' },
        { value: 'unknown', label: 'Unknown' },
    ],
    storeFormatOptions: [
        { value: 'main-estate', label: 'Main estate' },
        { value: 'convenience', label: 'Convenience' },
        { value: 'both', label: 'Both' },
        { value: 'unknown', label: 'Unknown' },
    ],
    noOfFeaturedSkuOptions: [
        { value: '0', label: '0' },
        { value: '1', label: '1' },
        { value: '2', label: '2' },
        { value: '3', label: '3' },
        { value: '4', label: '4' },
        { value: '5', label: '5' },
        { value: '6', label: '6' },
        { value: '7', label: '7' },
        { value: '8', label: '8' },
        { value: '9', label: '9+' },
        { value: 'unknown', label: 'Unknown' },
    ],
    otherMessagingOptions: [
        { value: 'health-messaging', label: 'Health messaging' },
        { value: 'meal-deal-inclusion-callout', label: 'Meal-deal inclusion callout' },
        { value: 'sustainability-environmental-messaging', label: 'Sustainability/environmental messaging' },
        { value: 'serving-suggestion', label: 'Serving suggestion' },
        { value: 'other', label: 'Other' },
        { value: 'unknown', label: 'Unknown' },
    ],
    overlayOptions: [ ...yesNoUnknownOptions, { value: 'no-competition', label: 'No competition' } ],
    onPackOptions: yesNoUnknownOptions,
    indexBenchmarks: [
        { minValue: 111, label: 'Above', color: 'success' }, // green
        { minValue: 90.5, label: 'Within 10%', color: 'primary' }, // blue
        { minValue: 0, label: 'Below', color: 'danger' }, // red
    ],
    demographics: {
        unit: [
            { label: 'Currency £', value: 'pound' },
            { label: 'Percent', value: 'percent' },
            { label: 'Value', value: 'value' },
        ],
        chartType: [
            { label: 'Pie', value: 'pie' },
            { label: 'Bar', value: 'column' },
        ],
    },
    testVsControl: {
        codeVersions: [
            { version: '1.0', date: new Date('2017-01-01T00:00:00+00:00') },
            { version: '2.0', date: new Date('2023-03-20T00:00:00+00:00') },
        ],
    }
};

export default studies;
