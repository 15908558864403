// Returns the value as type Number if input is a Number or String containing only a number
// Otherwise returns undefined
function ensureNumber(value) {
    let valueToTest = value;

    if (typeof value === 'string') {
        // Strings made up of blank spaces will be converted to 0 with Number(), but we want to reject these
        valueToTest = value.trim();
    }

    // Some values derive from text fields as Strings, we need to covert them to numbers for calculations
    if (!valueToTest && valueToTest !== 0) {
        // Number() converts null to 0, we want to reject null values
        return undefined;
    }

    const valueAsNumber = Number(valueToTest);
    return Number.isFinite(valueAsNumber) ? valueAsNumber : undefined;
}

function getRandomBetween(min = 0, max = 1) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

// Rounds value to 2 decimal places if value is longer than 2dp, otherwise returns value as is
function roundAsCurrency(value) {
    const valueAsNumber = ensureNumber(value);

    if (!valueAsNumber) return undefined;

    // Number.EPSILON handles issues with rounding floating point binary
    return Math.round((valueAsNumber + Number.EPSILON) * 100) / 100;
}

function precisionRound(number, precision = 0) {
    var factor = Math.pow(10, precision);
    return Math.round(number * factor) / factor;
}

export default {
    ensureNumber,
    getRandomBetween,
    precisionRound,
    roundAsCurrency,
};
