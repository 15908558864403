import { Icon } from 'Components';

import css from './ApprovalStateIcon.module.scss'

interface ApprovalStateIconProps {
    status: 'draft' | 'submitted' | 'approved';
}

const ApprovalStateIcon = (props: ApprovalStateIconProps) => {
    const { status } = props;

    const icons = {
        draft: 'pending',
        submitted: 'watch_later',
        approved: 'check_circle',
    } as const;

    return <Icon className={css[status]} name={icons[status]} />;
}

export default ApprovalStateIcon;
