import { Link, Navigate } from 'react-router-dom';

import { CompanyOverview, FeaturedTouchpoint, Imagery, RecentActivity, WhatsNew } from './Components';
import { Layout, Warning, useAppState, useAuth } from '../../Components';

import css from './HomePage.module.scss';
import { config } from 'smg-common';
import RecentlyAdded from './Components/RecentlyAdded';
import UpgradeInfobox from 'Components/Upgrade/UpgradeInfobox';
import { isGuestUser } from 'HelperFunctions';

const HomePage = () => {
    const { me } = useAuth();
    const [apps] = useAppState('apps');
    const isGuest = isGuestUser(me);

    // Hard code of 'Explore more' apps (includes some core apps)
    const exploreMoreApps = ['admin', 'gallery', 'glossary', 'media',];

    // Cannot use requiresAuth HOC for users with no company
    if (!me) return <Navigate to="/auth/login" />;
    const hasCompany = me.company && !me.requiresCompanyAssignment;

    const yourApps = apps.filter(app => !app.degraded && exploreMoreApps.indexOf(app.slug) < 0);
    const exploreMore = apps.filter(app => app.degraded || exploreMoreApps.indexOf(app.slug) > -1);

    // Order by campaign life cycle
    const ordering = {}
    const sortOrder = ['dashboard', 'budgeting', 'campaigns', 'allocator', 'evaluations', 'trends'];
    for (var i = 0; i < sortOrder.length; i++) ordering[sortOrder[i]] = i;
    yourApps.sort((a, b) => (ordering[a.slug] - ordering[b.slug]));

    const sidebar = (<div className="p-3">
        <h2>Hello {me.firstName},</h2>
        <h2 className={`${css.blue} mt-4`}>Your apps</h2>
        {yourApps.map(app => (
            <h3 key={app.slug} className="mt-3"><Link className="like-link no-color" to={app.path}>{app.name}</Link></h3>
        ))}
        <h2 className={`${css.blue} mt-4`}>Explore more</h2>
        {exploreMore.map(app => (
            <h3 key={app.slug} className="mt-3"><Link className="like-link no-color" to={app.path}>{app.name}</Link></h3>
        ))}

        {/* Training module - external link opens in new tab */}
        <a className="stack-start-center-1" href="https://plan-apps.getlearnworlds.com/" rel="noopener noreferrer" target="_blank">
            <h3 key="training" className="mt-3 like-link no-color">Training</h3>
        </a>

        {/* Guest user banner */}
        {isGuest &&
            <UpgradeInfobox>
                You're accessing Plan-Apps as a guest, upgrade
                to benefit from the full power of the platform.
            </UpgradeInfobox>
        }
    </div>);

    return (
        <>
            {!hasCompany &&
                <Layout data-smg-page="home" pageTitle="Home" size="md">
                    <Warning>
                        <p>Your account has been created successfully but needs to be assigned appropriate permissions before you can fully access Plan-Apps!</p>
                        <p>Our support team will activate your account. This normally happens within one business day.</p>
                        <p>For any queries please contact support at <a href={`mailto:${config.supportEmailAddress}`}>{config.supportEmailAddress}</a>.</p>
                    </Warning>
                </Layout>
            }

            {/* New home page */}
            {hasCompany &&
                <Layout data-smg-page="home" pageTitle="Home" sidebar={sidebar}>
                    <div className={css.row}>
                        <CompanyOverview className={css.widget} />
                        <WhatsNew className={css.widget} />
                    </div>
                    <div className={css.row}>
                        <FeaturedTouchpoint className={css.widget} />
                        <RecentlyAdded className={css.widget} />
                    </div>
                    <div className={css.row}>
                        <RecentActivity className={css.widget} />
                        <div className={css.widget} ></div>
                    </div>
                    <Imagery className={css.widget} />
                </Layout>
            }
        </>
    );
};

export default HomePage;
