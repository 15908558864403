import { campaigns } from '../config/index.js';
import { forEach, includes, uniq } from 'lodash-es';
import { getItemCost } from './itemFunctions.js';
import simpleDate from '../simpleDate.js';

function calculateEstimatedRoiAndPoundUplift(plan, activity, impressionsGroup) {
    const { baseSalesBrand } = plan;
    const { brandUpliftPercentLiveAndPost, brandRoiLiveAndPost, cost, duration, numberOfStores } = activity;
    let estPoundUpliftBrand;
    let estRoiUpliftBrand;

    // Issuance touchpoints use brandRoiLiveAndPost benchmark
    if (impressionsGroup === 'issuance') {
        estPoundUpliftBrand = Number.isFinite(cost) && Number.isFinite(brandRoiLiveAndPost)
            ? cost * brandRoiLiveAndPost
            : null;
        estRoiUpliftBrand = brandRoiLiveAndPost;
    } else {
        estPoundUpliftBrand = Number.isFinite(brandUpliftPercentLiveAndPost) && Number.isFinite(baseSalesBrand) && Number.isFinite(numberOfStores) && Number.isFinite(duration)
            // Add 28 days (4 weeks) to duration for post period
            ? baseSalesBrand * (brandUpliftPercentLiveAndPost.toFixed(1) / 100) * numberOfStores * ((duration + 28) / 7)
            : null;
        estRoiUpliftBrand = Number.isFinite(estPoundUpliftBrand) && Number.isFinite(cost)
            ? Math.round((estPoundUpliftBrand / cost) * 100) / 100
            : null;
    }

    return {
        estPoundUpliftBrand,
        estRoiUpliftBrand,
    };
}

// Calculates the "estimated pound uplift" and "estimated ROI" columns
// Pure function: returns a new activity
function addBenchmarkColumns(plan, activity, impressionsGroup) {
    return {
        ...activity,
        ...calculateEstimatedRoiAndPoundUplift(plan, activity, impressionsGroup),
    };
};

// Returns string of all uniq activity and item names in a plan
function getAllActivityAndItemNames(plan) {
    const names = [];
    forEach(plan.activities, activity => {
        names.push(activity.description || activity.touchpoint?.name);
    });

    forEach(plan.items, item => {
        names.push(item.title);
    });

    return uniq(names).join(', ');
}

// Returns the earliest start and latest end dates of activities & items
function getStartAndEndDatesOfActivitiesAndItems(activities, items) {
    let earliestStartDate;
    let latestEndDate;
    const activitiesAndItems = [...activities, ...items];

    activitiesAndItems.forEach(activityOrItem => {
        if (!earliestStartDate || simpleDate.isBefore(activityOrItem.startDate, earliestStartDate)) {
            earliestStartDate = activityOrItem.startDate;
        }

        if (!latestEndDate || simpleDate.isAfter(activityOrItem.endDate, latestEndDate)) {
            latestEndDate = activityOrItem.endDate;
        }
    });

    return {
        startDate: earliestStartDate,
        endDate: latestEndDate,
    };
}

// Calculates the sum of cost of a list of activities
function getTotalCostOfActivities(activities) {
    let totalSpend = null;
    activities.forEach(activity => {
        // Don't include cancelled activities in total cost
        if (Number.isFinite(activity.cost) && !activity.cancelled) {
            totalSpend += activity.cost
        }
    });
    return totalSpend;
};

// Calculates the total actual cost of activity and cost of items in a plan
function getTotalActualCostOfActivitiesAndItems(plan) {
    const totalActualCostOfActivities = plan.activities?.reduce((total, activity) => {
        // Don't include cancelled activities in total cost
        const activityActualCost = (Number.isFinite(activity.actualCost) && !activity.cancelled) ? Number(activity.actualCost) : 0;

        return total + activityActualCost;
    }, 0);

    // Percentage based 'other item costs' are calculated from the total media value (not the actual cost after discounts)
    const activitiesTotalCost = getTotalCostOfActivities(plan.activities);
    const totalCostOfItems = plan.items?.reduce((total, item) => total +  getItemCost(item, activitiesTotalCost), 0);

    return totalActualCostOfActivities + totalCostOfItems;
};

// Calculates the sum of estimated total media investment of a list of self-serve activities
function getTotalMediaInvestmentOfSelfServeActivities(activities) {
    let totalMediaInvestment = null;
    activities.forEach(activity => {
        const mediaInvestment = activity.estMetrics?.fees?.totalMediaInvestment;

        if (Number.isFinite(mediaInvestment)) {
            totalMediaInvestment += mediaInvestment
        }
    });
    return totalMediaInvestment;
};

// Calculates the sum of cost of all the activities and cost items of a plan
function getTotalCostOfActivitiesAndItems(plan) {
    const activitiesTotalCost = getTotalCostOfActivities(plan.activities);
    let totalSpend = activitiesTotalCost;
    plan.items && plan.items.forEach(item => {
        totalSpend += getItemCost(item, activitiesTotalCost);
    });
    return totalSpend;
}

// Calculates the sum of cost of selected the activities and cost items of a plan
function getTotalCostOfSelectedActivitiesAndItems(plan, activities, items) {
    // 1. Get cost of selected activities.
    const selectedActivitiesTotalCost = getTotalCostOfActivities(activities);

    /* 2. Get cost of selected items. Some items' costs are
    specified as a percentage of the total cost of the activities in the plan, so
    we need the cost of all activities in the plan, not just those in  selected activities. */
    const allActivitiesTotalCost = getTotalCostOfActivities(plan.activities);
    let itemsTotalCost = 0;
    items.forEach((item) => {
        itemsTotalCost += getItemCost(item, allActivitiesTotalCost)
    });

    return selectedActivitiesTotalCost + itemsTotalCost;
};

// Returns array of applicable columns for the activity table
function getTableColumns(canEditCampaigns, retailer, hasDiscounts = false) {
    let visibleColumns = campaigns.plans.activities.tableColumns;

    // If the user has no "edit" permissions, then override any custom column selection and remove the edit columns
    if (!canEditCampaigns) {
        const columnsToRemove = [
            'dragHandle',
            'selectActivities',
            'id',
            'actions',
        ];
        visibleColumns = visibleColumns.filter(col => !includes(columnsToRemove, col.value));
    }

    // If the retailer has no stores, then override any custom column selection and remove the stores column
    const hideStores = retailer?.noStores;
    if (hideStores) {
        visibleColumns = visibleColumns.filter(col => col.value !== 'stores');
    }

    // If any activities have discounts, then set the 'discounts' and 'actualCost' columns to required
    if (hasDiscounts) {
        visibleColumns = visibleColumns.map(col => {
            if (col?.isDiscounts) {
                return { ...col, isRequired: true };
            }
            return col;
        });
    }

    return visibleColumns;
}

// Calculates the final amount of all the discounts for the activity as a numerical value
const calculateActivityDiscountsValue = (activity) => {
    if (activity.cost === null || activity.cost === undefined) return null;
    if (activity.cost === 0) return 0;

    const totalDiscountPercentage = activity.discounts?.reduce((total, discount) => total + Number(discount.discountPercentage), 0) || 0;

    return activity.cost * (totalDiscountPercentage / 100);
}

// Calculates the actual cost of the activity after discounts have been applied
const calculateActualActivityCost = (activity) => {
    if (activity.cost === null || activity.cost === undefined) return null;
    if (activity.cost === 0) return 0;

    const totalDiscountAmount = calculateActivityDiscountsValue(activity)

    return activity.cost - totalDiscountAmount;
};

export {
    addBenchmarkColumns,
    calculateActualActivityCost,
    calculateActivityDiscountsValue,
    getAllActivityAndItemNames,
    getStartAndEndDatesOfActivitiesAndItems,
    getTableColumns,
    getTotalActualCostOfActivitiesAndItems,
    getTotalCostOfActivities,
    getTotalCostOfActivitiesAndItems,
    getTotalCostOfSelectedActivitiesAndItems,
    getTotalMediaInvestmentOfSelfServeActivities,
};
