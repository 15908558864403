import _ from 'lodash-es';
import {
    asdaTemplate,
    captureTemplate,
    coopTemplate,
    coopSocietiesTemplate,
    firstPartyDataTemplate,
    morrisonsInStoreTemplate,
    morrisonsOmnichannelTemplate,
    morrisonsOnlineTemplate,
    signetTemplate,
    tvgBuyerConfirmationBaseTemplate,
    tvgBuyerConfirmationBoostTemplate,
    tvgClientConfirmationTemplate,
} from './index.js';

const pandaDoc = {
    statusOptions: [
        { label: 'Uploaded', value: 'document.uploaded', color: 'smg-darkGrey', order: 1 },
        { label: 'Draft', value: 'document.draft', color: 'smg-darkGrey', order: 2 },
        { label: 'Sent', value: 'document.sent', color: 'success', order: 3 },
        { label: 'Viewed', value: 'document.viewed', color: 'info', order: 4 },
        { label: 'Waiting approval', value: 'document.waiting_approval', color: 'smg-mud', order: 5 },
        { label: 'Rejected', value: 'document.rejected', color: 'danger', order: 6 },
        { label: 'Approved', value: 'document.approved', color: 'success', order: 7 },
        { label: 'Waiting pay', value: 'document.waiting_pay', color: 'smg-mud', order: 8 },
        { label: 'Paid', value: 'document.paid', color: 'info', order: 9 },
        { label: 'Completed', value: 'document.completed', color: 'success', order: 10 },
        { label: 'Voided', value: 'document.voided', color: 'danger', order: 11 },
        { label: 'Declined', value: 'document.declined', color: 'danger', order: 12 },
        { label: 'Unknown', value: undefined, color: 'black', order: 13 },
    ],
    templates: [
        asdaTemplate,
        captureTemplate,
        coopTemplate,
        coopSocietiesTemplate,
        firstPartyDataTemplate,
        morrisonsInStoreTemplate,
        // PFX-6125: Hidden omnichannel template from options until concerns around double-billing clients are resolved
        // morrisonsOmnichannelTemplate,
        morrisonsOnlineTemplate,
        signetTemplate,
        tvgBuyerConfirmationBaseTemplate,
        tvgBuyerConfirmationBoostTemplate,
        tvgClientConfirmationTemplate,
    ],
    testFolderId: 'WrA7WoCe7PPzbLwusfewKn', // The PandaDoc ID of the "TESTING" folder, where we store all docs created in staging or dev environments
};

export default pandaDoc;
