import { useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import Header from '../Header';
import EnvironmentBanner from '../EnvironmentBanner';
import PopUpModal from '../PopUpModal';
import { Button, Http, Icon, useFetch, useLocalStorage, useLogPageView, useModal } from '../../';

import css from './Layout.module.scss';

// Returns the pathname replacing the param values with the param names
const getPathnameWithoutParams = (pathname, params) => {
    Object.keys(params).forEach((key) => {
        // useParams() includes the full params string under '*' which we can ignore
        if (key !== '*') {
            pathname = pathname.replace(params[key], `:${key}`);
        }
    });

    return pathname;
};

const Layout = (props) => {
    const {
        children,
        className,
        gutter = true,
        pageTitle,
        rightSidebar,
        showFooter = true,
        showHeader = true,
        sidebar,
        sidebarWidth,
        size,
        suppressPageViewLog = false, // when true, we do not log a page-view event in Kibana
        toolbar,
        trackUserActivity, // Ignored when suppressPageViewLog is true
        ...restProps
    } = props;

    useLogPageView({ suppressPageViewLog: suppressPageViewLog, trackUserActivity });

    const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [viewedPopUps] = useLocalStorage('popUps.viewed', []);
    const { show: showPopUpModal } = useModal({ template: PopUpModal });

    const { pathname } = useLocation();
    const params = useParams();
    const pathnameWithoutParams = getPathnameWithoutParams(pathname, params);

    // eslint-disable-next-line no-unused-vars
    const [popUps = []] = useFetch(
        () => Http.get('/pop-ups/routes', { params: { route: pathnameWithoutParams } })
            .then((data) => {
                if (data?.length) {
                    const newPopups = [];

                    // Automatically show new pop-ups
                    data.forEach(popUp => {
                        if (!viewedPopUps.includes(popUp._id)) {
                            newPopups.push(popUp);
                        }
                    });

                    if (newPopups.length) {
                        showPopUpModal({popUps: newPopups});
                    }
                }

                return data;
            }),
        [],
    );

    const popUpButtonLabel = popUps.length ? popUps[0].headline : undefined;
    const popUpButton = popUpButtonLabel ? <Button className={css.truncate} onClick={() => showPopUpModal({popUps})}>{popUpButtonLabel}</Button> : undefined;

    const contentWrapperClasses = [
        css['content-wrapper'],
        'stack-col-0',
    ];

    const contentClasses = [
        css.content,
        ...(size ? [css.centered, 'stack-col-start-center'] : ['stack-col-3']),
        'stack-stretch-items',
        css[size],
        gutter ? 'p-3' : '',
        className,
    ];

    const contentStyle = {
        ...(restProps.style ? restProps.style : {}),
    };

    return (
        <div className={`stack-col ${css.parent}`}>
            <EnvironmentBanner />
            {showHeader && <div className={css.header}><Header title={pageTitle} popUpButton={popUpButton} /></div>}
            {toolbar && <div className={css.toolbar}>{toolbar}</div>}
            {/**
             * The overflow: hidden allows the sidebar and content to scroll independently.
             * DO NOT CHANGE IT OR I WILL COME AND FIND YOU!
             */}
            <div className="stack" style={{ overflow: 'hidden' }}>
                {sidebar && (<aside className={`${css.sidebar} ${css['left-sidebar']} ${isSidebarCollapsed ? css.collapsed : ''}`.trim()}>
                    {!isSidebarCollapsed && (<div className={css.content}>{sidebar}</div>)}
                    <div className={css.toggle}>
                        <Icon name={isSidebarCollapsed ? 'chevron_right' : 'chevron_left'} onClick={() => setIsSidebarCollapsed(!isSidebarCollapsed)} />
                    </div>
                </aside>)}
                <div className={contentWrapperClasses.join(' ').trim()} style={contentStyle} {...restProps}>
                    <div className={contentClasses.join(' ').trim()}>
                        <div>{children}</div>
                    </div>
                    {showFooter && (
                        <div className={`${css.footer} stack-col-end-center-3 pb-3`}>
                            Copyright © Shopper Media Group Ltd. 2017-{new Date().getFullYear()}.
                        </div>
                    )}
                </div>
                {rightSidebar && (<aside className={css.sidebar}>
                    <div className={`${css.content} ${css['right-sidebar']}`}>{rightSidebar}</div>
                </aside>)}
            </div>

        </div>
    )
}

export default Layout;
