import { get, isEmpty } from 'lodash';

import { Button, Carousel, ColoredPanel, Format, GlossaryTerm, Icon, Percentage, PreviewFile, RichTextContent, Tooltip, useAuth, useModal } from '../../Components';
import AddToPlanModal from './AddToPlanModal';
import RequestQuoteModal from './RequestQuoteModal';
import TouchpointChart from './TouchpointChart';
import { daysToWeeks, getAttachmentTypeForTouchpoints, getCreativeTypeLabel, getHfssLabel, renderCost } from '../HelperFunctions';
import { formatters } from 'smg-common';


import css from './TouchpointBriefingPanel.module.scss';

const TouchpointBriefingPanel = (props) => {
    const { backLink, benchmarkData, isBookable, retailer, touchpoint, visiblePanels } = props;
    const { me } = useAuth();

    const { show: showAddToPlanModal } = useModal({title: `Add ${touchpoint.name} to plan`, template: AddToPlanModal});
    const isEcomm = touchpoint.shopperJourneySlug === 'e-commerce' || touchpoint.shopperJourneySlug === 'digital';
    const showCarousel = !visiblePanels || visiblePanels.includes('images');
    const showDescription = !visiblePanels || visiblePanels.includes('description');
    const showTrends = !visiblePanels || visiblePanels.includes('trends');
    const classes = (showCarousel || showDescription) && showTrends ? 'col-6' : '';

    const hasImages = touchpoint.images?.length > 0;
    const hasBenchmarkData = Object.values(benchmarkData)
        .reduce((containsData, dataGroup) => {
            return containsData
                || !isEmpty(dataGroup.upliftPercent)
                || !isEmpty(dataGroup.upliftPoundPerStore)
                || !isEmpty(dataGroup.roi);
        }, false);
    const hasEcommDetails = isEcomm
        && (
            benchmarkData?.touchpointMetrics?.avgImpressionsPerDay
                || benchmarkData?.touchpointMetrics?.avgClicksPerDay
                || benchmarkData?.touchpointMetrics?.avgCpmPerDay
        );
    const hasAttachments = touchpoint.attachments?.length > 0;
    const hasTouchpointAttachments = touchpoint.keyDocuments?.length > 0;
    const hasAnyTouchpointInformation = touchpoint.description || touchpoint.creative || touchpoint.reach
        || touchpoint.goodToKnow || touchpoint.deadlinesAndCost;
    const isAvailableInCampaigns = touchpoint.viewableIn?.includes('campaigns');
    const canEditCampaignsCampaigns = me.permissions.editCampaignsCampaigns;

    const handleRetailersAttachmentDownload = (touchpoint_id, attachment_id) => {
        const BASE_URL = process.env.REACT_APP_API_URL;
        window.location.href = `${BASE_URL}/admin/touchpoints/${touchpoint_id}/attachments/${attachment_id}`;
    }

    return (
        <div className="p-3 box-raised">
            <div className="stack-even-center mb-5">
                <h1>{touchpoint.name}</h1>
                <div>
                    {isAvailableInCampaigns && canEditCampaignsCampaigns && isBookable && (
                        <Button color="primary" onClick={() => showAddToPlanModal({ touchpoint })}>Add to plan</Button>
                    )}
                    {backLink && <Button className="ml-3" linkTo={backLink}>&lt; Back</Button>}
                </div>
            </div>
            <div className="row no-gutter mb-5">
                {/* Touchpoint images */}
                {hasImages && showCarousel && (
                    <div className={classes}>
                        <Carousel
                            delay={4000}
                            height="300px"
                            images={touchpoint.images}
                            showLegend={true}
                            style={{ maxWidth: '50vw' }}
                        />
                    </div>
                )}

                <div className={`box-flat p-3 ${classes}`}>
                    <h2>Key information</h2>
                    <table className="table">
                        <tbody>
                            {touchpoint.shopperJourneyLabel && <tr>
                                <th>Shopper journey</th>
                                <td>{touchpoint.shopperJourneyLabel}</td>
                            </tr>}
                            {touchpoint.cost && <tr>
                                <th>Cost</th>
                                <td>{renderCost(touchpoint)}</td>
                            </tr>}
                            {touchpoint.bookingDeadline && <tr>
                                <th>Booking deadline</th>
                                <td>{daysToWeeks(touchpoint.bookingDeadline)}</td>
                            </tr>}
                            {touchpoint.briefingDeadline && <tr>
                                <th>Briefing deadline</th>
                                <td>{daysToWeeks(touchpoint.briefingDeadline)}</td>
                            </tr>}
                            {touchpoint.briefingApprovalDeadline && me.company.type === 'internal' && <tr>
                                <th>Briefing approval deadline</th>
                                <td>{daysToWeeks(touchpoint.briefingApprovalDeadline)}</td>
                            </tr>}
                            {touchpoint.initialCreativeDeadline && me.company.type === 'internal' && <tr>
                                <th>Initial creative deadline</th>
                                <td>{daysToWeeks(touchpoint.initialCreativeDeadline)}</td>
                            </tr>}
                            {touchpoint.initialCreativeApprovalDeadline && me.company.type === 'internal' && <tr>
                                <th>Initial creative approval deadline</th>
                                <td>{daysToWeeks(touchpoint.initialCreativeApprovalDeadline)}</td>
                            </tr>}
                            {touchpoint.artworkDeadline && <tr>
                                <th>Artwork deadline</th>
                                <td>{daysToWeeks(touchpoint.artworkDeadline)}</td>
                            </tr>}
                            {touchpoint.artworkApprovalDeadline && me.company.type === 'internal' && <tr>
                                <th>Artwork approval deadline</th>
                                <td>{daysToWeeks(touchpoint.artworkApprovalDeadline)}</td>
                            </tr>}
                            {touchpoint.duration && <tr>
                                <th>Default duration</th>
                                <td>{daysToWeeks(touchpoint.duration)}</td>
                            </tr>}
                            {/* PFX-5582 Do not show HFSS or creative type if 'unknown' */}
                            {touchpoint.hfssCompliant && touchpoint.hfssCompliant !== 'unknown' && <tr>
                                <th><GlossaryTerm slug="HFSS">HFSS compliant</GlossaryTerm></th>
                                <td>{getHfssLabel(touchpoint.hfssCompliant)}</td>
                            </tr>}
                            {!isEmpty(touchpoint.workflowTemplates) && (
                                <tr>
                                    <th>Creative Type</th>
                                    <td>
                                        {touchpoint.workflowTemplates
                                            .filter(workflowTemplate => workflowTemplate.creativeType !== 'unknown')
                                            .map((workflowTemplate, index) => (<p key={index}>{getCreativeTypeLabel(workflowTemplate.creativeType)}</p>))
                                        }
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>

            {(hasAnyTouchpointInformation || hasEcommDetails || hasBenchmarkData) && (
                <div className="row no-gutter">
                    {/* Text description */}
                    {hasAnyTouchpointInformation && showDescription && (
                        <div className={classes}>
                            <div className="box-flat p-3">
                                <h2 className="mb-2">Details</h2>
                                {touchpoint.description && (<>
                                    <h3>Description</h3>
                                    <RichTextContent className="my-3" text={touchpoint.description} />
                                </>)}
                                {touchpoint.details && (<>
                                    <h3>Details &amp; insights</h3>
                                    <p>{touchpoint.details}</p>
                                </>)}
                                {touchpoint.creativeDetails && (<>
                                    <h3>Creative Details</h3>
                                    <p>{touchpoint.creativeDetails}</p>
                                </>)}
                                {touchpoint.reach && (<>
                                    <h3>Reach</h3>
                                    <p>{touchpoint.reach}</p>
                                </>)}
                                {touchpoint.goodToKnow && (<>
                                    <h3>Good to know</h3>
                                    <p>{touchpoint.goodToKnow}</p>
                                </>)}
                            </div>
                        </div>
                    )}

                    {(hasEcommDetails || hasBenchmarkData) && showTrends && (
                        <div className={`stack-col-5 mb-5 p-3 box-flat ${classes}`}>
                            {!hasBenchmarkData && <h2>Benchmark</h2>}
                            {/* Benchmarks chart */}
                            {hasBenchmarkData && (<div className={`mb-3 ${css['chart-container']}`}>
                                <TouchpointChart benchmarkData={benchmarkData} themeGrey title="Benchmark" />
                            </div>)}
                            {!isBookable && <ColoredPanel className="my-3 p-3 d-flex flex-row w-100" color="info-light">
                                <div className="mr-3" >
                                    <Icon name="info"/>
                                </div>
                                <div>These figures aren't category specific and are based on total Plan-Apps data for this touchpoint.</div>
                            </ColoredPanel>}
                            {/* Digital benchmarks table */}
                            {hasEcommDetails && (
                                <table className="table my-0">
                                    <thead>
                                        <tr>
                                            <th>Average</th>
                                            <th>Per day</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Impressions</td>
                                            <td><Format.AsNumber value={benchmarkData?.touchpointMetrics?.avgImpressionsPerDay} /></td>
                                        </tr>
                                        <tr>
                                            <td>CPM</td>
                                            <td>{<Format.AsCurrency value={benchmarkData?.touchpointMetrics?.avgCpmPerDay} decimals={2} na="n/a" />}</td>
                                        </tr>
                                        <tr>
                                            <td>Engagement (clicks/opens)</td>
                                            <td>{<Format.AsNumber value={benchmarkData?.touchpointMetrics?.avgClicksPerDay} />}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            )}

                            {/* In-store benchmarks table */}
                            {hasBenchmarkData && (
                                <table className="table my-0">
                                    <thead>
                                        <tr>
                                            <th>Average</th>
                                            <th className="text-right">Feat SKU</th>
                                            <th className="text-right">Brand</th>
                                            <th className="text-right">Category</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><GlossaryTerm slug="percent-uplift">% uplift</GlossaryTerm></td>
                                            <td className="text-right"><Percentage number={get(benchmarkData, 'featuredSku.upliftPercent.value')} fixedDecimals={1} invalid="n/a" /></td>
                                            <td className="text-right"><Percentage number={get(benchmarkData, 'brand.upliftPercent.value')} fixedDecimals={1} invalid="n/a" /></td>
                                            <td className="text-right"><Percentage number={get(benchmarkData, 'category.upliftPercent.value')} fixedDecimals={1} invalid="n/a" /></td>
                                        </tr>
                                        <tr>
                                            <td><GlossaryTerm slug="uplift-pound-per-store">£ uplift per store</GlossaryTerm></td>
                                            <td className="text-right"><Format.AsCurrency value={get(benchmarkData, 'featuredSku.upliftPoundPerStore.value')} decimals={0} na="n/a" /></td>
                                            <td className="text-right"><Format.AsCurrency value={get(benchmarkData, 'brand.upliftPoundPerStore.value')} decimals={0} na="n/a" /></td>
                                            <td className="text-right"><Format.AsCurrency value={get(benchmarkData, 'category.upliftPoundPerStore.value')} decimals={0} na="n/a" /></td>
                                        </tr>
                                        <tr>
                                            <td><GlossaryTerm slug="weighted-roi">ROI live &amp; post period</GlossaryTerm></td>
                                            <td className="text-right"><Format.AsCurrency value={get(benchmarkData, 'featuredSku.roi.value')} decimals={2} na="n/a" /></td>
                                            <td className="text-right"><Format.AsCurrency value={get(benchmarkData, 'brand.roi.value')} decimals={2} na="n/a" /></td>
                                            <td className="text-right">n/a</td>
                                        </tr>
                                    </tbody>
                                </table>
                            )}
                        </div>
                    )}
                </div>
            )}
            {hasAttachments && <div className="box-flat p-3">
                <h2>Key documents</h2>
                <table className="table">
                    <tbody>
                        {touchpoint.attachments.map((attachment) => (
                            <tr key={attachment.file._id}>
                                <td>{attachment.file.name}</td>
                                <td>
                                    <PreviewFile
                                        className="mr-2"
                                        file={attachment.file}
                                        files={touchpoint.attachments.map(a => a.file)}
                                        trigger={(<Tooltip align="left" hover="Preview file">
                                            <Icon className="like-link subtle ml-2" name="preview" />
                                        </Tooltip>)}
                                    />
                                    <Tooltip hover="Download file" align="left">
                                        <Icon
                                            className="like-link subtle"
                                            name="cloud_download"
                                            // TODO: add handleDownloadAttachment function once TP attachments are added
                                            // onClick={() => handleDownloadAttachment(documentType, attachment)}
                                        />
                                    </Tooltip>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>}

            {hasTouchpointAttachments && <div className="box-flat p-3 mt-5">
                <h2>Key documents</h2>
                <table className="table">
                    <thead>
                        <tr>
                            <th>File type</th>
                            <th>File name</th>
                            <th>Upload date</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {touchpoint.keyDocuments.map((attachment) => (
                            <tr key={attachment.file._id}>
                                <td>{getAttachmentTypeForTouchpoints(attachment.documentType)}</td>
                                <td>{attachment.file.name}</td>
                                <td>{formatters.asDate(attachment.file.createdAt)}</td>
                                <td>
                                    <PreviewFile
                                        className="mr-2"
                                        file={attachment.file}
                                        trigger={(<Tooltip align="left" hover="Preview file">
                                            <Icon className="like-link subtle ml-2" name="preview" />
                                        </Tooltip>)}
                                    />
                                    <Tooltip hover="Download file" align="left">
                                        <Icon
                                            className="like-link subtle mr-2"
                                            name="cloud_download"
                                            onClick={() => handleRetailersAttachmentDownload(touchpoint._id, attachment._id)}
                                        />
                                    </Tooltip>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>}

            {isBookable && <RequestQuoteModal touchpoint={touchpoint} retailer={retailer} />}
        </div>
    )
}

export default TouchpointBriefingPanel;
